import {EmployeeType} from '../../shared/models/employee-type.enum';
import {EmployeeSeniority} from '../../shared/models/employee-seniority.enum';
import {UserRole} from './user-role.enum';

export class User {
	readonly uuid: string;
	readonly email: string;
	readonly fullName: string;
	readonly seniority: EmployeeSeniority; // only for doctors
	readonly signatureUrl: string; // only for doctors
	readonly roles: Array<UserRole>;
	readonly defaultClinicUuid: string;
	readonly type: EmployeeType;

	constructor(params?: {
		uuid: string,
		email: string,
		defaultClinicUuid: string,
		fullName: string,
		type: EmployeeType,
		roles: Array<UserRole>;
		seniority?: EmployeeSeniority,
		signatureUrl?: string
	}) {
		if (params) {
			Object.assign(this, params);
		}
	}

	static fromStorage(params: {
		uuid: string,
		email: string,
		fullName: string,
		seniority: string,
		type: string,
		roles: Array<string>,
		signatureUrl: string,
		defaultClinicUuid: string,
	}): User {
		params.seniority = EmployeeSeniority[params.seniority];
		params.type = EmployeeType[params.type];
		params.roles = params.roles?.map((role: string) => UserRole[role]);
		return Object.assign(new User(), params);
	}

	static deserialize(response): User {
		const params = {
			uuid: response.uuid,
			email: response.email,
			fullName: response.name,
			defaultClinicUuid: response.employerClinicUuid,
			type: EmployeeType[response.type],
			roles: response.roles?.map((role: string) => UserRole[role]),
			seniority: null,
			signatureUrl: null
		};
		if (response.seniority) {
			params.seniority = EmployeeSeniority[response.seniority];
		}
		if (response.signatureUrl) {
			params.signatureUrl = response.signatureUrl;
		}
		return new User(params);
	}

	isClinicManager(): boolean {
		return this.type === EmployeeType.ClinicManager;
	}

	isDoctor(): boolean {
		return this.type === EmployeeType.Doctor;
	}

	isConcierge(): boolean {
		return this.type === EmployeeType.Concierge;
	}

	isCounselor(): boolean {
		return this.type === EmployeeType.Counselor;
	}

	isChief(): boolean {
		return this.roles.includes(UserRole.Chief);
	}

	isMedicalTeam(): boolean {
		return this.type === EmployeeType.MedicalTeam;
	}

	isViewer(): boolean {
		return this.type === EmployeeType.Viewer;
	}

	isAdmin(): boolean {
		return this.type === EmployeeType.Admin;
	}
}
