import jwtDecode, {JwtPayload} from 'jwt-decode';

export const isApiTokenExpired = (apiToken: string): boolean => {
	try {
		const decoded: JwtPayload = jwtDecode<JwtPayload>(apiToken);
		return decoded.exp < (new Date().getTime() / 1000); // in seconds
	} catch (error: any) {
		return true;
	}
};
